import { Col } from "antd";
import React from "react";

import * as classes from "./CurriculumDetails.module.scss";
import ModuleCard from "./ModuleCard";
import { CustomButton } from "../../adaptors/CustomButton";
import { variants } from "../../../utils/variants";
import { DIGITAL_MARKETING_PAGE_FILES_PATH } from "../../../utils/localization";
import { curriculum } from "../Utils/data";

const CurriculumDetails = (props) => {
  const { setLeadSquareModalOpen } = props;

  const handleDownloadCuricullum = () => {
    setLeadSquareModalOpen(true);
  };

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.top}>
          <Col>
            <h1>Best in industry curriculum</h1>
            <p>
              Best-in-class content by leading faculty and industry leaders in
              the form of videos, live sessions and projects
            </p>
          </Col>
          <Col className={classes.stats}>
            <Col>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/training-1.svg`}
                alt="digital marketing institute internship"
              />
              <h3>17 Weeks Of Extensive Training</h3>
            </Col>
            <Col>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/projects.svg`}
                alt="digital marketing internship program"
              />
              <h3>5+ Case Studies and Projects</h3>
            </Col>
            <Col>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/tools.svg`}
                alt="digital marketing job training"
              />
              <h3>10+ tools and skills</h3>
            </Col>
            <Col>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/live-sessions.svg`}
              />
              <h3>Over 20 Live Interactions</h3>
            </Col>
          </Col>
          <Col className={classes.curicullum}>
            <Col className={classes.left}>
              {curriculum.map((module) => {
                return <ModuleCard module={module} key={module.id} />;
              })}
            </Col>
            <Col className={classes.right}>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/dg-brochure.png`}
                alt="jobs after digital marketing course"
              />
              <CustomButton
                title={"Download Course Curriculum"}
                variant={variants.primaryButton}
                customClass={classes.buttonCustomClass}
                customTextClass={classes.buttonCustomTextClass}
                onClick={handleDownloadCuricullum}
              />
            </Col>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default CurriculumDetails;
