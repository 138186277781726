// extracted by mini-css-extract-plugin
export var buttonCustomClass = "Hero-module--buttonCustomClass--ab7a3";
export var buttonCustomTextClass = "Hero-module--buttonCustomTextClass--c5948";
export var courseDescription = "Hero-module--courseDescription--5d410";
export var courseHeroContainer = "Hero-module--courseHeroContainer--6f9c2";
export var courseHeroContentLeft = "Hero-module--courseHeroContentLeft--f4bdd";
export var courseHeroContentRight = "Hero-module--courseHeroContentRight--4c03b";
export var courseHeroWrapper = "Hero-module--courseHeroWrapper--c81cf";
export var courseRating = "Hero-module--courseRating--50559";
export var courseStats = "Hero-module--courseStats--909de";
export var courseStatsItem = "Hero-module--courseStatsItem--a4a35";
export var coursesDetails = "Hero-module--coursesDetails--0ad70";
export var heroTitle = "Hero-module--heroTitle--55b68";
export var horizontalRuler = "Hero-module--horizontalRuler--68b34";
export var lmsScrollbar = "Hero-module--lms-scrollbar--15386";
export var videoContainer = "Hero-module--videoContainer--07bd7";
export var videoIframe = "Hero-module--videoIframe--f632f";