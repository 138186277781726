import React from "react";
import { Col, Typography } from "antd";

import * as classes from "./Hero.module.scss";
import StarRatings from "../../StarRatings";
import { variants } from "../../../utils/variants";
import { CustomButton } from "../../adaptors/CustomButton";
import { DIGITAL_MARKETING_PAGE_FILES_PATH } from "../../../utils/localization";

const { Title, Paragraph } = Typography;

const Hero = (props) => {
  const { setLeadSquareModalOpen } = props;

  return (
    <Col className={classes.courseHeroContainer}>
      <Col className={classes.courseHeroWrapper}>
        <Col className={classes.courseHeroContentLeft}>
          <Title className={classes.heroTitle}>
            Advanced Certificate in Digital Marketing and Communication
          </Title>
          <Col className={classes.courseRating}>
            <StarRatings rating={4.2} reviewers={"1400+"} spacing={4} />
          </Col>
          <Paragraph className={classes.courseDescription}>
            Become a Digital Marketing expert by specialising in Social Media
            and Content Marketing, Branding, Marketing Analytics and Public
            Relations. Start your Digital Marketing journey with Unschool today.
          </Paragraph>
          <Col className={classes.courseStats}>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/shield.svg`}
                alt="digital marketing institute internship"
                loading="lazy"
              />
              <span>Lifelong Access</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/level.svg`}
                alt="digital marketing internship program"
                loading="lazy"
              />
              <span>Advanced</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/group.svg`}
                alt="digital marketing job training"
                loading="lazy"
              />
              <span>5107+ learners</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/language.svg`}
                alt="jobs after digital marketing course"
                loading="lazy"
              />
              <span>Language: English</span>
            </Col>
            <Col className={classes.courseStatsItem}>
              <img
                src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/chapters.svg`}
                alt="digital marketing course with internship"
                loading="lazy"
              />
              <span>43 Chapters, 65 Hours</span>
            </Col>
          </Col>
          <CustomButton
            title={"Apply Now"}
            variant={variants.primaryButton}
            customClass={classes.buttonCustomClass}
            customTextClass={classes.buttonCustomTextClass}
            onClick={() => {
              setLeadSquareModalOpen(true);
            }}
          />
        </Col>
        <Col className={classes.courseHeroContentRight}>
          <Col>
            <Col className={classes.videoContainer}>
              <iframe
                className={classes.videoIframe}
                src="https://player.vimeo.com/video/736491793?h=e311b4a50a&badge=0&autopause=0&player_id=0&app_id=58479"
                title=" Advanced Certificate in Digital Marketing and Communication"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen={true}
                id="iframe"
              />
              <script src="https://player.vimeo.com/api/player.js" />
            </Col>
          </Col>
        </Col>
        <Col className={classes.coursesDetails}>
          <Col>
            <h3>Next Cohort Starts 15th May, 2023</h3>
            <p>Limited seats only available</p>
          </Col>
          <Col className={classes.horizontalRuler}></Col>
          <hr />
          <Col>
            <h3>Program Duration: 6 Months</h3>
            <p>Approx. 8-10 hours/week</p>
          </Col>
          <Col className={classes.horizontalRuler}></Col>
          <hr />

          <Col>
            <h3>Job Guarantee after Completion</h3>
            <p>1 Week of Career Assistance</p>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Hero;
