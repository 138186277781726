import { Col } from "antd";
import React from "react";
import { useEffect } from "react";

import * as classes from "./Reviews.module.scss";

const ReviewCard = (props) => {
  const { review } = props;

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 600) {
      const reviewCards = document.getElementsByClassName(classes.reviewCard);
      let maxEleHeight = 0;
      for (let i = 0; i < reviewCards.length; i++) {
        if (reviewCards[i].offsetHeight > maxEleHeight) {
          maxEleHeight = reviewCards[i].offsetHeight;
        }
      }
      for (let i = 0; i < reviewCards.length; i++) {
        reviewCards[i].style.height = `${maxEleHeight}px`;
      }
    }
  }, []);

  return (
    <Col className={classes.reviewCard}>
      <Col>
        <img src={review.image} alt="digital marketing institute internship" />
      </Col>
      <Col>
        <h3>{review.name}</h3>
        <div dangerouslySetInnerHTML={{ __html: review.review }}></div>
      </Col>
      <Col>
        <a href={review.href} target="_blank">
          <img src={review.logo} alt="digital marketing internship program" />
        </a>
      </Col>
    </Col>
  );
};

export default ReviewCard;
