import { Col } from "antd";
import React from "react";

import * as classes from "./Coaches.module.scss";

const CoachCard = (props) => {
  const { coach } = props;

  return (
    <Col className={classes.coach}>
      <img src={coach.imageUrl} alt="jobs after digital marketing course" />
      <Col className={classes.coachDetails}>
        <Col>
          <h3 className={classes.coachName}>{coach.name}</h3>
          <h3 className={classes.course}>{coach.designation}</h3>
        </Col>
        <img
          className={classes.companyLogo}
          src={coach.companyLogo}
          alt="digital marketing course with job guarantee"
        />
      </Col>
    </Col>
  );
};

export default CoachCard;
