// extracted by mini-css-extract-plugin
export var bottom = "CurriculumDetails-module--bottom--d46be";
export var buttonCustomClass = "CurriculumDetails-module--buttonCustomClass--932c0";
export var buttonCustomTextClass = "CurriculumDetails-module--buttonCustomTextClass--0d9b0";
export var container = "CurriculumDetails-module--container--30ec7";
export var curicullum = "CurriculumDetails-module--curicullum--8f5fe";
export var curicullumItem = "CurriculumDetails-module--curicullumItem--b8412";
export var left = "CurriculumDetails-module--left--f2f7e";
export var lmsScrollbar = "CurriculumDetails-module--lms-scrollbar--87f50";
export var minusToggleIcon = "CurriculumDetails-module--minusToggleIcon--cb35b";
export var plusToggleIcon = "CurriculumDetails-module--plusToggleIcon--4b96c";
export var projectsTop = "CurriculumDetails-module--projectsTop--f5122";
export var projectsTopBottom = "CurriculumDetails-module--projectsTopBottom--6ea06";
export var right = "CurriculumDetails-module--right--316bd";
export var stats = "CurriculumDetails-module--stats--b3e69";
export var toggleIcon = "CurriculumDetails-module--toggleIcon--4e1dd";
export var top = "CurriculumDetails-module--top--f420a";
export var wrapper = "CurriculumDetails-module--wrapper--164e3";