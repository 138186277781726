// extracted by mini-css-extract-plugin
export var achievements = "AchivementsMobile-module--achievements--f3170";
export var achievementsContent = "AchivementsMobile-module--achievementsContent--4355a";
export var cardFiveMobile = "AchivementsMobile-module--cardFiveMobile--c36cb";
export var cardFourMobile = "AchivementsMobile-module--cardFourMobile--8bdce";
export var cardOneMobile = "AchivementsMobile-module--cardOneMobile--cf283";
export var cardSixMobile = "AchivementsMobile-module--cardSixMobile--50d36";
export var cardThreeMobile = "AchivementsMobile-module--cardThreeMobile--c24ea";
export var cardTwoMobile = "AchivementsMobile-module--cardTwoMobile--65eec";
export var container = "AchivementsMobile-module--container--d3117";
export var lmsScrollbar = "AchivementsMobile-module--lms-scrollbar--c5a07";
export var stats = "AchivementsMobile-module--stats--139d6";
export var statsContainer = "AchivementsMobile-module--statsContainer--7ddf6";