export default {
  metaTags: {
    titleMetaTagContent:
      "Digital Marketing Job Guarantee Program | 100% job placement | Unschool",
    descriptionMetaTagContent:
      "Start your Digital Marketing Certification with 100% Job guarantee program with Unschool today.",
    keywordsMetaTagContent:
      "digital marketing course online,digital marketing certificate,online marketing courses,digital marketing training,learn digital marketing,best digital marketing course,marketing certifications,digital marketing course near me,digital marketing basics,digital marketing classes,marketing certificate programs,best online digital marketing courses,digital marketing courses after 12th,digital marketing certification course,internet marketing course,digital marketing course details,certified,digital marketing professional,digital marketing certificatdigital marketing course,e online,best online marketing courses,best digital marketing certification,digital media courses,digital marketing short course,online marketing training,digital marketing programs,online marketing courses with certificates,marketing certification courses,learn digital marketing online,advanced digital marketing course,digital marketing training courses,best marketing certifications,online marketing certificate,performance marketing course,digital marketing classes online,complete digital marketing course,digital marketing full course,digital marketing course eligibility,digital marketing course outline,internet marketing training,jobs with a digital marketing certificate,digital marketing job program,digital marketing certificate jobs,fast earn digital marketing,digital marketing institute internship,digital marketing internship program,digital marketing job training,jobs after digital marketing course,digital marketing course with job guarantee,digital marketing course with internship,digital marketing course with job placement,digital marketing course job guarantee,career after digital marketing course,digital marketing training with internship",
    robotsMetaTagContent: "index, follow",
    httpEquivMetaTagContent: "text/html; charset=utf-8",
    languageMetaTagContent: "English",
    revisitAfterMetaTagContent: "1 days",
    authorMetaTagContent: "Unschool",
  },
  scriptTags: [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: "Digital Marketing Job Program",
      image:
        "https://www.unschool.in/static/Unschool%20Logo%20Horizontal%20White%20(1)-a4913f0c391a347aa53acfa994243cb4.png",
      description:
        "Start your Digital Marketing Certification with 100% Job guarantee program with Unschool today.",
      brand: "Unschool",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "3.5",
        ratingCount: "1276",
      },
      sku: "Internship Program",
      mpn: "UA-134257618-1",
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name: "Will any preparation material be provided as part of this digital marketing program to help me get started?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Yes, our experts have carefully curated the curriculum for your end to end learning and plenty of materials, guidelines and individual support will be provided from the dedicated Unschool teaching faculty and a team of trained professionals",
          },
        },
        {
          "@type": "Question",
          name: "What should I expect from the digital marketing certificate program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Over 90 hours of hybrid learning including practical aspects such as live training, projects, case studies, career assistance support and much more.",
          },
        },
        {
          "@type": "Question",
          name: "What is the eligibility criteria for applying for this program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "If you are fresh graduate who are looking for a job. Experienced professional who want to switch careers. Existing digital marketing professional who wants to upskill yourself with industry experts and live projects This program is for you!",
          },
        },
        {
          "@type": "Question",
          name: "What is the application process?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You need to apply by submitting the interest form online. Candidates will have a conversation with our in-house digital marketing specialist to understand the program. Shortlisted candidates will be awarded an offer letter of admission that they can accept by paying the program fee",
          },
        },
        {
          "@type": "Question",
          name: "How long is the program?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Overall it is a 6 months program with 4 months of learning with pre-recorded and live sessions and 2 months of career assistance program.",
          },
        },
      ],
    },
    {
      "@context": "http://schema.org",
      "@type": "VideoObject",
      name: "Advanced Certificate in Digital Marketing and Communication",
      description:
        "Become a Digital Marketing expert by specializing in Social Media and Content Marketing, Branding, Marketing Analytics, and Public Relations. Start your Digital Marketing journey with Unschool today.",
      thumbnailUrl: "https://i.ytimg.com/vi/FdkGRbxNlx8/default.jpg",
      uploadDate: "2023-01-05T08:19:37Z",
      duration: "PT2M8S",
      embedUrl: "https://www.youtube.com/embed/FdkGRbxNlx8",
      interactionCount: "70",
    },
  ],
  imageAltAttributes: [
    "digital marketing institute internship",
    "digital marketing internship program",
    "digital marketing job training",
    "jobs after digital marketing course",
    "digital marketing course with job guarantee",
    "digital marketing course with internship",
    "digital marketing course with job placement",
    "digital marketing course job guarantee",
    "career after digital marketing course",
    "digital marketing training with internship",
  ],
};
