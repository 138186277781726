// extracted by mini-css-extract-plugin
export var container = "Tools-module--container--8b238";
export var heading = "Tools-module--heading--3fbe4";
export var left = "Tools-module--left--39f6b";
export var lmsScrollbar = "Tools-module--lms-scrollbar--78cad";
export var right = "Tools-module--right--65851";
export var toolFeatureItem = "Tools-module--toolFeatureItem--4a573";
export var tools = "Tools-module--tools--0cba6";
export var toolsDesktop = "Tools-module--toolsDesktop--b1745";
export var toolsMobile = "Tools-module--toolsMobile--fa233";
export var wrapper = "Tools-module--wrapper--9e094";