// extracted by mini-css-extract-plugin
export var accordianHeader = "Faq-module--accordianHeader--2e798";
export var accordianItem = "Faq-module--accordianItem--7645b";
export var accordianText = "Faq-module--accordianText--fcba7";
export var contactUnschool = "Faq-module--contactUnschool--3d0e4";
export var courseFaqContainer = "Faq-module--courseFaqContainer--8136b";
export var courseFaqContent = "Faq-module--courseFaqContent--a5113";
export var courseFaqContentLeft = "Faq-module--courseFaqContentLeft--63516";
export var courseFaqContentRight = "Faq-module--courseFaqContentRight--33ab5";
export var faqImageContainer = "Faq-module--faqImageContainer--cd32a";
export var headingContainer = "Faq-module--headingContainer--d8750";
export var headingDesktop = "Faq-module--headingDesktop--e8045";
export var headingMobile = "Faq-module--headingMobile--3649e";
export var iconClassName = "Faq-module--iconClassName--cc10d";
export var lmsScrollbar = "Faq-module--lms-scrollbar--0d152";