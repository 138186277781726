// extracted by mini-css-extract-plugin
export var bottom = "CourseStats-module--bottom--878f5";
export var cardFive = "CourseStats-module--cardFive--abb17";
export var cardFour = "CourseStats-module--cardFour--77b65";
export var cardOne = "CourseStats-module--cardOne--e594a";
export var cardSix = "CourseStats-module--cardSix--b7465";
export var cardThree = "CourseStats-module--cardThree--d018e";
export var cardTwo = "CourseStats-module--cardTwo--cf983";
export var container = "CourseStats-module--container--fa5ee";
export var left = "CourseStats-module--left--c052b";
export var lmsScrollbar = "CourseStats-module--lms-scrollbar--ecfb9";
export var right = "CourseStats-module--right--91ab8";
export var rightDesktop = "CourseStats-module--rightDesktop--51931";
export var stats = "CourseStats-module--stats--47f9f";
export var statsContainer = "CourseStats-module--statsContainer--5e73b";
export var top = "CourseStats-module--top--5e258";
export var wrapper = "CourseStats-module--wrapper--808ae";