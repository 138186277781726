import { Col, Typography } from "antd";
import React from "react";

import * as classes from "./CourseStats.module.scss";
import { DIGITAL_MARKETING_PAGE_FILES_PATH } from "../../../utils/localization";
import { stats } from "../Utils/data";

const { Title } = Typography;

const CourseStats = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.top}>
          <Col className={classes.left}>
            <Col className={classes.top}>
              <h3>
                <span>
                  <img
                    src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/left.png`}
                    alt="digital marketing institute internship"
                    loading="lazy"
                  />
                </span>
                150+
                <span>
                  <img
                    src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/right.png`}
                    alt="digital marketing internship program"
                    loading="lazy"
                  />
                </span>
              </h3>
              <h4>Students Currently Enrolled</h4>
            </Col>
            <Col className={classes.bottom}>
              <Col className={classes.left}>
                <img
                  src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/salary.png`}
                  alt="digital marketing job training"
                  loading="lazy"
                />
                <Col>
                  <h3>5.5-6 LPA</h3>
                  <h3>Average</h3>
                  <h3>Salary</h3>
                </Col>
              </Col>
              <Col className={classes.right}>
                <img
                  src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/placement.png`}
                  alt="jobs after digital marketing course"
                  loading="lazy"
                />
                <Col>
                  <h3>90%</h3>
                  <h3>Placement</h3>
                  <h3>Rate</h3>
                </Col>
              </Col>
            </Col>
          </Col>
          <Col className={classes.right}>
            <Col className={classes.rightDesktop}>
              <Col className={classes.cardOne}>
                <img
                  src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/bussiness-world.png`}
                  alt="digital marketing course with job guarantee"
                  loading="lazy"
                />
                <p>
                  Hyderabad Youths Create Start-Up- 'Unschool, To Bridge Gap
                </p>
              </Col>
              <Col className={classes.cardTwo}>
                <img
                  src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/linkedIn.svg`}
                  alt="digital marketing course with internship"
                  loading="lazy"
                />
                <p>
                  Unschool is ranked 3rd on LinkedIn 2020 top startup list in
                  India.
                </p>
              </Col>
              <Col className={classes.cardThree}>
                <img
                  src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/print.svg`}
                  alt="digital marketing course with job placement"
                  loading="lazy"
                />
                <p>Preparing India for the future workplace.</p>
              </Col>
              <Col className={classes.cardFour}>
                <img
                  src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/combinator.svg`}
                  alt="digital marketing course job guarantee"
                  loading="lazy"
                />
                <p>Y Combinator widens its bet in edtech in latest batch.</p>
              </Col>
              <Col className={classes.cardFive}>
                <h3>
                  <span>Unschool</span> In
                </h3>
                <h3>The News</h3>
                <Col />
              </Col>
              <Col className={classes.cardSix}>
                <img
                  src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/your-story.svg`}
                  alt="career after digital marketing course"
                  loading="lazy"
                />
                <p>
                  This Hyderabad-based edtech startup aims to help students
                  ‘un’school themselves.
                </p>
              </Col>
            </Col>
          </Col>
        </Col>
        <Col className={classes.statsContainer}>
          <Col className={classes.stats}>
            {stats.map((stat) => {
              return (
                <Col key={stat.id}>
                  <Title>{stat.value}</Title>
                  <p>{stat.title}</p>
                </Col>
              );
            })}
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default CourseStats;
