// extracted by mini-css-extract-plugin
export var buttonCustomClass = "Coaches-module--buttonCustomClass--46a9f";
export var buttonCustomTextClass = "Coaches-module--buttonCustomTextClass--227fe";
export var coach = "Coaches-module--coach--71165";
export var coachCarouselDesktop = "Coaches-module--coachCarouselDesktop--f9063";
export var coachCarouselMobile = "Coaches-module--coachCarouselMobile--44b50";
export var coachDetails = "Coaches-module--coachDetails--7fba6";
export var coachName = "Coaches-module--coachName--ff7ca";
export var companyLogo = "Coaches-module--companyLogo--d6a19";
export var container = "Coaches-module--container--da6f2";
export var controlButtons = "Coaches-module--controlButtons--649f7";
export var course = "Coaches-module--course--e2bba";
export var lmsScrollbar = "Coaches-module--lms-scrollbar--e857c";
export var wrapper = "Coaches-module--wrapper--85c2e";