import { Col } from "antd";
import React from "react";
import { DIGITAL_MARKETING_PAGE_FILES_PATH } from "../../../utils/localization";

import * as classes from "./LearningJourney.module.scss";

const LearningJourney = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <h1>Your Learning Journey</h1>
        <Col>
          <img
            src={`${DIGITAL_MARKETING_PAGE_FILES_PATH}/learning-journey.png`}
            alt="digital marketing job training"
          />
        </Col>
      </Col>
    </Col>
  );
};

export default LearningJourney;
