import { DIGITAL_MARKETING_PAGE_FILES_PATH } from "../../../utils/localization";

export const stats = [
  { value: "200k+", title: "Learners", id: 1 },
  { value: "500K+", title: "Enrollments", id: 2 },
  { value: "100%", title: "Placements", id: 3 },
  { value: "150+", title: "Industry experts", id: 4 },
  { value: "150+", title: "Hiring Partners", id: 5 },
];

export const reviews = [
  {
    id: 1,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/Alka Kumari.jpg`,
    name: "Alka Kumari",
    review: `I’m happy to share that I’ve completed my Digital marketing job programme at Unschool ! I have got very supportive and familiar environment here. All members from here always motivated me and helped me to stepped forward❤️. <br/>
      I have learnt many things about Digital marketing. Before joining this program i only know about digital marketing is that just through Internet marketing but that time I didn't know that how🙈 ¿???? <br/>
      But now I know what is digital marketing and how is it work. <br/>
      Now I know what is "SMM", "SEM" , "SEO". I have learnt how to run a compaign and how publish it😍.`,
    logo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/alka27_hello-connections-im-happy-to-share-activity-7037349310782484480-4Q7O/?utm_source=share&utm_medium=member_android",
  },
  {
    id: 2,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/Koushik Sai.jpg`,
    name: "Koushik Sai",
    review: `I'll be the one to say that I have completed my Digital marketing course 👨🏻‍🎓which is 4 months span by the Unschool .I can't believe that it happened so fast 😅 and feeling happy to share that.<br/>
    They made me know and learn about the different digital marketing channels and 'SEO', 'SEM', 'SMM' and different platforms of marketing by the hands on experience, live campaigns and case studies through the live sessions.<br/>
    The way of learning here is very comfortable and flexible with the well experienced mentors in there relevant field who makes me to achieve this course.<br/>
    And thanks to the Co founder & CBO of this wonderful platform and interacting with one to one interactions also Narayanan S sir !`,
    logo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/koushiksai_digitalmarketing-learning-activity-7037372872406818816-qqNo/?utm_source=share&utm_medium=member_android",
  },
  {
    id: 3,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/Manish.jpg`,
    name: "Manish",
    review: `I've gained a lot of skill.<br/>
    managing a budget working with a team, building a display ad, generating content for Facebook, adding SEO, and understanding ROAS. <br/>
    I truly learned a lot about how third-party apps like Canva assist us produce eye-catching add while working on various learning projects like Spotify and FC Goa online football couching. I am very appreciative of Unschool for teaching me about digital marketing and turning me into a pro. Many thanks Unschool.`,
    logo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/manish-sha_digitalamarketing-digitaladvertising-digital-activity-7039560151422439424-lRff/?utm_source=share&utm_medium=member_android",
  },
  {
    id: 4,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/avatar.png `,
    name: "Pranay",
    review: `I am happy to share that I have completed my 4 months digital marketing jobs program from Unschool. <br/> I have learnt the digital marketing in core, where I understood STP, Facebook Ads Campaign, SEO, Google Ads and many more things!`,
    logo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
    href: "https://www.linkedin.com/posts/pranay-sheelam-148b10267_hello-connections-i-am-happy-to-share-that-activity-7037364053723209728-LWk0/?utm_source=share&utm_medium=member_android",
  },
];

export const brands = [
  {
    id: 1,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/leadSquare.svg`,
  },
  {
    id: 2,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/linkedIn-full-icon.svg`,
  },
  {
    id: 3,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/combinator.svg`,
  },
  {
    id: 4,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/near.svg`,
  },
  {
    id: 5,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/village-capital.svg`,
  },
  {
    id: 6,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/mailmado.svg`,
  },
  {
    id: 7,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/aws-ed-start.svg`,
  },
  {
    id: 8,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/lpi.svg`,
  },
  {
    id: 9,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/pwc.svg`,
  },
  {
    id: 10,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/global-shapers-logo.svg`,
  },
];

export const overviews = [
  {
    id: 1,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/certificate.svg`,
    title: "Industry Certification",
    description: "Multiple certification opportunities from Unschool.",
  },
  {
    id: 2,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/expert.svg`,
    title: "Worldclass Experts",
    description:
      "Access to Industry Experts and Teaching Assistants through the program",
  },
  {
    id: 3,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/live-sessions.svg`,
    title: "Live Interactions",
    description:
      "Fortnightly immersive Guest lectures with Industry Leaders to Rekindle Your Mind",
  },
  {
    id: 4,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/training.svg`,
    title: "Hands on Training",

    description: "Live Projects and Relevant Case Studies through the program",
  },
  {
    id: 5,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/immerse-learning.svg`,
    title: "Immersive Learning",
    description:
      "Module Based Quizzes and Assessments to Test Your Knowledge at every stage",
  },
  {
    id: 6,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/doubt-solving.svg`,
    title: "Doubt Solving",
    description:
      "Weekly Live Sessions of upto 3 hours with Live QnA every week for 60 minutes",
  },
  {
    id: 7,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/video-content.svg`,
    title: "Video Content",
    description: "Self Paced Learning with over 25+ hours of Video Content",
  },
  {
    id: 8,
    icon: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/job-assistance.svg`,
    title: "Job Assistance",
    description: "Mock interviews and Guidance in Resume Preparation",
  },
];

export const curriculum = [
  {
    id: 1,
    title: "Fundamentals of Digital Marketing",
    stats: "2 Weeks | 1 Casestudy | 2 Quizzes",
    content: [
      { id: 1, title: "Chapter 1: Introduction To Marketing" },
      { id: 2, title: "Chapter 2: Segmentation, Targeting And Positioning" },
      {
        id: 3,
        title: "Chapter 3: Marketing Management Through The Concept Of 4ps",
      },
      { id: 4, title: "Chapter 4: Introduction To Branding" },
    ],
  },
  {
    id: 2,

    title: "Digital Marketing Channels and Metrics",
    stats: "1 Week | 2 Casestudy | 1 Quiz",
    content: [
      { id: 1, title: "Chapter 1: Understanding The Digital Consumer" },
      { id: 2, title: "Chapter 2: Digital Marketing Channels" },
      { id: 3, title: "Chapter 3: Digital Marketing Metrics" },
    ],
  },
  {
    id: 3,

    title: "Social Media Marketing",
    stats: "2 Weeks | 1 Live-project | 4 Quizzes",
    content: [
      {
        id: 1,
        title: "Chapter 1: Establishing A Brand’s Social Media Presence",
      },
      { id: 2, title: "Chapter 2: Creating A Social Media Plan" },
      { id: 3, title: "Chapter 3: Ampliying Social Media Content" },
      {
        id: 4,
        title: "Chapter 4: Analyse And Optimise Social Media Performance",
      },
    ],
  },
  {
    id: 4,

    title: "Advanced Search Engine Optimisation",
    stats: "2 Weeks | 1 Casestudy | 2 Quizzes",
    content: [
      { id: 1, title: "Chapter 1: Introduction To Search Engine Optimisation" },
      { id: 2, title: "Chapter 2: SEO: Keyword Strategy" },
      { id: 3, title: "Chapter 3: SEO: Auditing And Strategy" },
    ],
  },
  {
    id: 5,
    title: "Display Advertising",
    stats: "2 Weeks | 1 Casestudy | 2 Quizzes",
    content: [
      { id: 1, title: "Chapter 1: Introduction To Display Advertising" },
      { id: 2, title: "Chapter 2: Affiliate Marketing And Ad Networks" },
      { id: 3, title: "Chapter 3: Ad Exchange And Progammatic Ad Buying" },
      { id: 4, title: "Chapter 4: Future Of Display Advertising" },
    ],
  },
  {
    id: 6,
    title: "User Journey and Nurturing",
    stats: "2 Weeks | 1 Casestudy | 2 Quizzes",
    content: [
      {
        id: 1,
        title: "Chapter 1: Lead Management",
      },
      { id: 2, title: "Chapter 2: Email Marketing And Strategy" },
      { id: 3, title: "Chapter 3: Email Marketing Analysis And Optimisation" },
      { id: 4, title: "Chapter 4: Remarketing And The Channels To Master" },
    ],
  },
  {
    id: 7,
    title: "Website Analytics",
    stats: "3 Weeks | 1 Casestudy | 4 Quizzes",
    content: [
      { id: 1, title: "Chapter 1: Introduction To Website Analytics" },
      { id: 2, title: "Chapter 2: Marketing Analytics And Data Collection" },
      { id: 3, title: "Chapter 3: Google Analytics: Module 1" },
      { id: 4, title: "Chapter 3: Google Analytics: Module 2" },
    ],
  },
];

export const coaches = [
  {
    name: "Tanmoy Das",
    designation: "Assistant Content Manager",
    imageUrl: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/das.png`,
    companyLogo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/regali.png`,
    id: 1,
  },
  {
    name: "Simran Sukhnani",
    designation: "Senior Content Executive",
    imageUrl: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/simran.png`,
    companyLogo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/purple.png`,
    id: 99,
  },
  {
    name: "Kushal Bharti",
    designation: "Head of Growth",
    imageUrl: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/biswal.png`,
    companyLogo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/typof.png`,
    id: 3,
  },
  {
    name: "Megha Gulati",
    designation: "Marketing Consultant",
    imageUrl: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/megha.png`,
    companyLogo: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/sri.png`,
    id: 4,
  },
];

export const hiringCompanies = [
  {
    id: 1,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/accenture.png`,
  },
  {
    id: 2,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/amazon.png`,
  },
  {
    id: 3,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/siemens.png`,
  },
  {
    id: 4,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/xiomi.png`,
  },
  {
    id: 5,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/hotstar.png`,
  },
  {
    id: 6,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/medianet.png`,
  },
  {
    id: 7,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/slx.png`,
  },
  {
    id: 8,

    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/freshworks.png`,
  },
  {
    id: 9,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/delta-x.png`,
  },
];

export const tools = [
  {
    id: 1,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/google-analytics.png`,
  },
  {
    id: 2,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/hootsuit.png`,
  },
  {
    id: 3,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/hubspot.png`,
  },
  {
    id: 4,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/mailchip.png`,
  },
  {
    id: 5,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/meta.png`,
  },
  {
    id: 6,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/spyfu.png`,
  },
  {
    id: 7,
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/twitter-analytics.png`,
  },
  {
    id: 8,

    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/wordpress.png`,
  },
];

export const advantages = [
  {
    id: 1,
    title: "Case Studies & Projects",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/advantage-icon-1.png`,
    content: [
      { id: 1, title: "Solve real-life industry problems" },
      {
        id: 2,
        title:
          "Brush-up your sales skills by working on the latest industry case studies",
      },
    ],
  },
  {
    id: 2,
    title: "Teaching Assistance",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/advantage-icon-2.png`,
    content: [
      {
        id: 1,
        title: "Get round the clock mentor support to get your doubts resolved",
      },
      {
        id: 2,
        title:
          "Receive one-on-one feedback on submissions and personalized feedback on improvement",
      },
    ],
  },
  {
    id: 3,
    title: "Industry relevant content",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/advantage-icon-3.png`,
    content: [
      {
        id: 1,
        title:
          "Learn best-in-class content by leading faculty and industry leaders in the form of videos, case studies, projects, assignments and live sessions",
      },
      {
        id: 2,
        title: "Get hands-on experience with trending sales tools",
      },
    ],
  },
];

export const toolsFeaturesDesktopOne = [
  {
    id: 1,
    title: "Digital Marketing",
  },
  {
    id: 4,
    title: "Instagram Marketing",
  },
  {
    id: 7,
    title: "Facebook Marketing",
  },
];

export const toolsFeaturesDesktopTwo = [
  {
    id: 2,
    title: "Pay Per Click",
  },
  {
    id: 5,
    title: "Keyword Research",
  },
  {
    id: 8,
    title: "Email Marketing",
  },
];

export const toolsFeaturesDesktopThree = [
  {
    id: 3,
    title: "YouTube Analytics",
  },
  {
    id: 6,
    title: "Web Analytics",
  },
  {
    id: 9,
    title: "Search Engine optimization",
  },
];

export const toolsFeaturesMobileOne = [
  {
    id: 1,
    title: "Digital Marketing",
  },
  {
    id: 4,
    title: "Instagram Marketing",
  },
  {
    id: 7,
    title: "Facebook Marketing",
  },
  {
    id: 2,
    title: "Pay Per Click",
  },
  {
    id: 5,
    title: "Keyword Research",
  },
];

export const toolsFeaturesMobileTwo = [
  {
    id: 8,
    title: "Email Marketing",
  },
  {
    id: 3,
    title: "YouTube Analytics",
  },
  {
    id: 6,
    title: "Web Analytics",
  },
  {
    id: 9,
    title: "Search Engine optimization",
  },
];

export const faqs = [
  {
    question:
      "Will any preparation material be provided as part of this digital marketing program to help me get started?",
    answer:
      "Yes, our experts have carefully curated the curriculum for your end to end  learning and plenty of materials, guidelines and individual support will be provided from the dedicated Unschool teaching faculty and a team of trained professionals",
  },
  {
    question:
      "What should I expect from the digital marketing certificate program?",
    answer:
      "Over 90 hours of hybrid learning including practical aspects such as live training, projects, case studies, career assistance support and much more.",
  },
  {
    question: "What is the eligibility criteria for applying for this program?",
    answer:
      "If you are fresh graduate who are looking for a job. Experienced professional who want to switch careers. Existing digital marketing professional who wants to upskill yourself  with industry experts and live projects This program is for you!",
  },
  {
    question: "What is the application process?",
    answer:
      "You need to apply by submitting the interest form online. Candidates will have a conversation with our in-house digital marketing specialist to understand the program. Shortlisted candidates will be awarded an offer letter of admission that they can accept by paying the program fee",
  },
  {
    question: "How long is the program?",
    answer:
      "Overall it is a 6 months program with 4 months of learning with pre-recorded and live sessions and 2 months of career assistance program.",
  },
];

export const caseStudies = [
  {
    id: 1,
    title: "Social Media Ads Campaign",
    description:
      "Make a Facebook ad campaign strategy for a brand and run campaign for brand awareness, you will learn skill set of making an advertising campaign from scratch on Facebook and Instagram.",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/buzz-feed.png`,
  },
  {
    id: 2,
    title: "Social Media Case Study",
    description:
      "Learn how the brand was able to leverage social media marketing to reduce their CPA and drive sales.",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/master-card.png`,
  },
  {
    id: 3,
    title: "Content Marketing with Email Marketing",
    description:
      "Prepare a content calendar and a content marketing strategy for a newly opened food joint and prepare an email marketing",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/dominos.png`,
  },
  {
    id: 4,
    title: "Website and User Case Study",
    description:
      "Understand the different parameters involved in building a website pertaining to multiple target groups and geographies",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/trip-advisor.png`,
  },
  {
    id: 5,
    title: "SEO Structure Planning",
    description:
      "Suggest On-Page and Off-Page strategies for an online travel booking portal and learn SEO with a hands-on activity",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/booking.png`,
  },
  {
    id: 6,
    title: "Google Ads Campaign",
    description:
      "Make a Google advertising campaign for a BFSI client and run Search Campaigns to drive traffic",
    image: `${DIGITAL_MARKETING_PAGE_FILES_PATH}/oyo.png`,
  },
];
