import { Col } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";

import { caseStudies } from "../Utils/data";
import * as classes from "./CaseStudies.module.scss";
import { ReactSlickController } from "../../adaptors/ReactSlickController";
import { isSSR } from "../../../helpers/global";

const CaseStudies = () => {
  const ref = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const [buttonsController, setButtonController] = useState({
    next: () => {},
    prev: () => {},
    slickGoTo: () => {},
  });

  const calculateNoOfSildesToShow = useMemo(() => {
    if (isSSR()) {
      return 0;
    } else {
      const width = window?.innerWidth;
      if (width <= 725) {
        return 1;
      }
      if (width <= 1000) {
        return 2;
      }
      return 3;
    }
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    slidesToShow: 3,
    speed: 500,
    rows: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 725,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const slickController = { ...ref?.current };
    setButtonController((state) => ({
      ...state,
      next: slickController?.slickNext,
      prev: slickController?.slickPrev,
      slickGoTo: slickController?.slickGoTo,
    }));
  }, [ref]);

  useEffect(() => {
    setTimeout(() => {
      const caseStudyItems = document.getElementsByClassName(
        classes.caseWrapper
      );
      let maxEleHeight = 0;
      for (let i = 0; i < caseStudyItems.length; i++) {
        if (caseStudyItems[i].offsetHeight > maxEleHeight) {
          maxEleHeight = caseStudyItems[i].offsetHeight;
        }
      }
      for (let i = 0; i < caseStudyItems.length; i++) {
        caseStudyItems[i].style.height = `${maxEleHeight}px`;
      }
    }, 1000);
  }, []);

  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.heading}>
          <h1>
            <span>Industry Projects and Case Studies</span>
          </h1>
          <p>
            Our Industry experts come from renowned industries and with years of
            experience.
          </p>
        </Col>
        <Col className={classes.caseStudies}>
          <Slider ref={ref} {...settings}>
            {caseStudies.map((study) => {
              return (
                <Col className={classes.caseStudyItem} key={study.id} ref={ref}>
                  <Col className={classes.caseWrapper}>
                    <h3>{study.title}</h3>
                    <p>{study.description}</p>
                    <img
                      src={study.image}
                      alt="digital marketing internship program"
                    />
                  </Col>
                </Col>
              );
            })}
          </Slider>
          <Col className={classes.controlButtons}>
            <ReactSlickController
              next={buttonsController.next}
              prev={buttonsController.prev}
              slickGoTo={buttonsController.slickGoTo}
              activeSlide={activeSlide}
              totalSlides={caseStudies.length}
              slidesToShow={calculateNoOfSildesToShow}
            />
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default CaseStudies;
