import React from "react";
import { Col } from "antd";

import * as classes from "./Tools.module.scss";
import {
  tools,
  toolsFeaturesDesktopOne,
  toolsFeaturesDesktopThree,
  toolsFeaturesDesktopTwo,
  toolsFeaturesMobileOne,
  toolsFeaturesMobileTwo,
} from "../Utils/data";
import { SALES_PAGE_FILES_PATH } from "../../../utils/localization";

const Tools = () => {
  return (
    <Col className={classes.container}>
      <Col className={classes.wrapper}>
        <Col className={classes.left}>
          <Col className={classes.heading}>
            <h1>Learn all the industry relevant tools</h1>
            <p>
              Get acquainted with 20+ In demand hands on digital marketing
              tools, SEO Tools, social media marketing tools, keyword research
              tools, analytics tools and many more.
            </p>
          </Col>
          <Col className={classes.toolsDesktop}>
            <Col>
              {toolsFeaturesDesktopOne.map((feature) => {
                return (
                  <Col className={classes.toolFeatureItem} key={feature.id}>
                    <img
                      src={`${SALES_PAGE_FILES_PATH}/tickIconYellow.svg`}
                      alt="digital marketing course with internship"
                    />
                    <p>{feature.title}</p>
                  </Col>
                );
              })}
            </Col>
            <Col>
              {toolsFeaturesDesktopTwo.map((feature) => {
                return (
                  <Col className={classes.toolFeatureItem} key={feature.id}>
                    <img
                      src={`${SALES_PAGE_FILES_PATH}/tickIconYellow.svg`}
                      alt="digital marketing course with job placement"
                    />
                    <p>{feature.title}</p>
                  </Col>
                );
              })}
            </Col>
            <Col>
              {toolsFeaturesDesktopThree.map((feature) => {
                return (
                  <Col className={classes.toolFeatureItem} key={feature.id}>
                    <img
                      src={`${SALES_PAGE_FILES_PATH}/tickIconYellow.svg`}
                      alt="digital marketing course job guarantee"
                    />
                    <p>{feature.title}</p>
                  </Col>
                );
              })}
            </Col>
          </Col>
          <Col className={classes.toolsMobile}>
            <Col>
              {toolsFeaturesMobileOne.map((feature) => {
                return (
                  <Col className={classes.toolFeatureItem} key={feature.id}>
                    <img src={`${SALES_PAGE_FILES_PATH}/tickIconYellow.svg`} />
                    <p>{feature.title}</p>
                  </Col>
                );
              })}
            </Col>
            <Col>
              {toolsFeaturesMobileTwo.map((feature) => {
                return (
                  <Col className={classes.toolFeatureItem} key={feature.id}>
                    <img
                      src={`${SALES_PAGE_FILES_PATH}/tickIconYellow.svg`}
                      alt="career after digital marketing course"
                    />
                    <p>{feature.title}</p>
                  </Col>
                );
              })}
            </Col>
          </Col>
        </Col>
        <Col className={classes.right}>
          <Col className={classes.tools}>
            {tools.map((tool) => {
              return (
                <Col key={tool.id}>
                  <img
                    src={tool.image}
                    alt="digital marketing training with internship"
                  />
                </Col>
              );
            })}
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Tools;
